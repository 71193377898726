import React, { useState, useEffect } from 'react'
import { Form, Button, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import axios from 'axios'
import { useParams, useNavigate, Link } from 'react-router-dom'
import ScgCard from '../components/ScgCard'
import Message from '../components/Message'

const UserUpdateScreen = () => {
  const { id } = useParams()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [password, setPassword] = useState('')
  const [paidCategory, setPaidCategory] = useState('')
  const [prefixes, setPrefixes] = useState([])
  const [addedConference, setAddedConference] = useState([])
  const [messageText, setMessageText] = useState('')
  const [messageVariant, setMessageVariant] = useState('')

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const navigate = useNavigate()

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`,
    },
  }

  const getUserDetails = async (id) => {
    const {
      data: { name, email, password, isAdmin, paidCategory, prefixes },
    } = await axios.get(`/api/users/${id}`, config)

    setName(name)
    setEmail(email)
    setPassword(password)
    setIsAdmin(isAdmin)
    setPaidCategory(`${paidCategory}`)
    setPrefixes(prefixes)
  }

  const userUpdate = async () => {
    try {
      await axios.put(
        `/api/users/update`,
        { id, name, email, password, isAdmin, paidCategory, prefixes },
        config
      )
      setMessageText(`User ${name} updated`)
      setMessageVariant('green')
      setTimeout(() => {
        navigate(`/admin/userlist`)
      }, 1500)
    } catch (error) {
      setMessageText(`Error response ${error.response.status}`)
      setMessageVariant('red')
    }
  }

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      console.log('Hello admin')
      getUserDetails(id)
    } else {
      console.log('You are not admin')
    }
  }, [])

  const submitHandler = (e) => {
    e.preventDefault()
    userUpdate()
  }

  const addConferenceHandler = () => {
    setPrefixes([...prefixes, addedConference])
    setAddedConference('')
  }

  const deleteConferenceHandler = (prefix) => {
    setPrefixes(
      prefixes.filter((p) => {
        return p !== prefix
      })
    )
  }

  return (
    <ScgCard scrollable={true}>
      <Message text={messageText} variant={messageVariant}></Message>

      <FormContainer>
        <h1>Edit User</h1>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='name'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='name'
              placeholder='Enter name'
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='email'>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter email'
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='password'>
            <Form.Label>Password Address</Form.Label>
            <Form.Control
              type='password'
              placeholder='Enter password'
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='isAdmin'>
            <Form.Label>Admin</Form.Label>
            <Form.Check
              type='switch'
              checked={isAdmin}
              onChange={(e) => setIsAdmin(e.target.checked)}
            />
          </Form.Group>

          <Form.Group controlId='paidCategory'>
            <Form.Label>Paid Category</Form.Label>

            <Form.Select
              label='paidCategory'
              value={paidCategory}
              onChange={(e) => setPaidCategory(e.target.value)}
              style={
                paidCategory === '1'
                  ? { backgroundColor: '#CD7F32', color: 'white' }
                  : paidCategory === '2'
                  ? {
                      backgroundColor: 'rgb(189 187 187 / 86%)',
                      color: 'white',
                    }
                  : paidCategory === '3'
                  ? { backgroundColor: '#C29B0C', color: 'white' }
                  : { backgroundColor: 'grey' }
              }
            >
              <option value={1}>Bronze</option>
              <option value={2}>Silver</option>
              <option value={3}>Gold</option>
            </Form.Select>
          </Form.Group>

          <Form.Group controlId='addedConference'>
            <Form.Label>Add Conference</Form.Label>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Form.Control
                type='text'
                label='addedConferece'
                value={addedConference}
                onChange={(e) => setAddedConference(e.target.value)}
              />

              <Button
                type='button'
                variant='success'
                disabled={addedConference.length === 0}
                style={{ marginLeft: '1rem' }}
                onClick={addConferenceHandler}
              >
                Add
              </Button>
            </div>
          </Form.Group>

          <div className='centralize'>
            <Table
              style={{ width: '50%' }}
              bordered
              hover
              responsive
              striped
              size='sm'
              variant='light'
              className='table-sm my-2'
            >
              <thead>
                <tr>
                  <th>Prefix</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {prefixes.map((prefix) => (
                  <tr key={prefix}>
                    <td>{prefix}</td>
                    <td>
                      <Button
                        variant='danger'
                        className='btn-sm'
                        onClick={() => {
                          deleteConferenceHandler(prefix)
                        }}
                      >
                        <i className='fas fa-minus'></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-evenly',
            }}
          >
            <Link to='/admin/userlist' className='btn btn-light my-3'>
              Go Back
            </Link>
            <Button
              type='submit'
              className='buttonCta'
              disabled={prefixes.length === 0}
            >
              Update User
            </Button>
          </div>
        </Form>
      </FormContainer>
    </ScgCard>
  )
}

export default UserUpdateScreen
