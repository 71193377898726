import React from 'react'
import classes from './ScgCard.module.css'

const ScgCard = ({ children, scrollable = false }) => {
  return (
    <div
      className={` ${classes.card}` + ` ` + (scrollable ? 'scrollable' : '')}
    >
      {children}
    </div>
  )
}

export default ScgCard
