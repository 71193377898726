import React from 'react';
import { Button } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import classes from './AfterPaymentScreen.module.css';

const AfterPaymentScreen = () => {
  const { prefix, success } = useParams();
  const successfulPurchase = `Thank you for your purchase. An email wiil be sent to you with the
  submission codes. If you do not recieve the email in the next 5 minutes
  please contact us at support@scigentech.com`;

  const failedPurchase = `It seems that your payment did not succeed. If you are having trouble
  please contact us at support@scigentech.com`;

  const query = new URLSearchParams(window.location.search).get('author_email');

  return (
    <>
      {success === 'success' ? (
        <div className={classes.cardContainer}>
          <div className={classes.flexItemLeftSuccess}>
            {/* <h1>ePostersLive</h1> */}
            <h2>Thank you for your purchase</h2>
            <p>Copyright &copy; 2010-2024 SciGen® Technologies.</p>
          </div>
          <div className={classes.flexItemRightSuccess}>
            <diV>An email with the submission code(s) you purchased will be sent to you within the next few minutes.</diV>
            <div>
              If you have any questions please contact us at <a href={`mailto:support@scigentech.com`}>support@scigentech.com</a>
            </div>
            <div className={classes.buttonContainer}>
              <Link to={`/submissioncodes/payments/${prefix}?email=${query}`}>
                <Button type='submit' className={classes.goBackSuccess}>
                  &larr; Go Back
                </Button>
              </Link>
            </div>
          </div>
        </div>
      ) : (
        <div className={classes.cardContainer}>
          <div className={classes.flexItemLeftFail}>
            {/* <h1>ePostersLive</h1> */}
            <p style={{fontSize: '1.2rem'}}>We are sorry but it seems your payment did not go as expected</p>
            <p>Copyright &copy; 2010-2024 SciGen® Technologies.</p>
          </div>
          <div className={classes.flexItemRightFail}>
            <div>We apologise for any inconvenience.</div>
            <div>
              If you are having any trouble with your payment please contact us at{' '}
              <a href={`mailto:support@scigentech.com`}>support@scigentech.com</a>.
            </div>
            <div className={classes.buttonContainer}>
              <Link to={`/submissioncodes/payments/${prefix}`}>
                <Button type='submit' className={classes.goBackFail}>
                  &larr; Go Back
                </Button>
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AfterPaymentScreen;
