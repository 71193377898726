import React, { useState, useEffect } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { Form, Button, Row, Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import Message from '../components/Message'
import Loader from '../components/Loader'
import FormContainer from '../components/FormContainer'
import { login } from '../actions/userActions'
import classes from './LoginScreen.module.css'
const LoginScreen = ({ history }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const userLogin = useSelector((state) => state.userLogin)
  const { loading, error, userInfo } = userLogin

  useEffect(() => {
    if (userInfo) navigate('/analytics/home')
  }, [history, userInfo])

  const submitHandler = (e) => {
    e.preventDefault()
    dispatch(login(email, password))
  }

  return (
    <div className={classes.loginContainer}>
      {error && (
        <Message text='Invalid email or password' variant='red'></Message>
      )}
      <FormContainer>
        <div className='placeItemsCenter'>
          <img src='https://scigen-assets.s3.eu-west-1.amazonaws.com/eposters-analytics/eposterslive-new-logo.png' />
        </div>
        {loading && <Loader />}
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='email'>
            <Form.Label className={classes.emailLabel}>Email</Form.Label>
            <Form.Control
              type='email'
              placeholder='john@gmail.com'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='password'>
            <Form.Label className={classes.passwordLabel}>Password</Form.Label>
            <Form.Control
              type='password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <div className={classes.privacyPolicy}>
            By logging in you agree to the{' '}
            <a
              target='_blank'
              href='https://eposterslive.com/privacy-policy'
              style={{ color: '#166088', textDecoration: 'none' }}
            >
              Privacy Policy
            </a>{' '}
            and to the use of cookies.
          </div>
          <div className='placeItemsCenter'>
            <Button
              type='submit'
              className={classes.loginCta}
              disabled={email.length === 0}
            >
              Login
            </Button>
          </div>
        </Form>
      </FormContainer>
    </div>
  )
}

export default LoginScreen
