import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Grid } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import classes from './AnalyticsScreen.module.css';

const AudiosAnalyticsScreen = () => {
  const { prefix } = useParams();

  const [audiosVisited, setAudiosVisited] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [messageText, setMessageText] = useState('');
  const [messageVariant, setMessageVariant] = useState('');

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const keys = [
    'Audio Title',
    'Number of Plays',
    'Number of Impressions',
    'Number of Full Plays',
    'Average Time Watched in seconds',
  ];

  const options = {
    filterType: 'checkbox',
    selectableRows: 'none', // <===== will turn off checkboxes in rows
    rowsPerPageOptions: [{ label: '10', value: 10 }],
  };

  //Matomo returns conferences with other prefixes
  const isLabelClear = (videoView) => {
    if (
      videoView.label?.includes(
        `epostersonline-2.s3.amazonaws.com/${prefix}/${prefix}`
      )
    )
      return true;
    else return false;
  };

  const formatAudioAnalyticsData = async (audiosArray) => {
    try {
      let { data } = await axios.get(
        `/api/analytics/codes/audio/${prefix}`,
        config
      );

      let audiosViewed = audiosArray.filter(isLabelClear);
      let audiosFinalArray = audiosViewed.map((audio) => {
        return [
          data[`${audio.label.split('/').pop()}`],
          audio.nb_plays,
          audio.nb_impressions,
          audio.nb_finishes,
          audio.avg_time_watched,
        ];
      });
      setAudiosVisited(audiosFinalArray);
      setIsLoading(false);
    } catch (error) {
      const status = error.response.status;
      if (status === 400) {
        setMessageText('No data found');
        setMessageVariant('red');
        setIsLoading(false);
      }
    }
  };

  const fetchAudios = async () => {
    try {
      let { data } = await axios.get(`/api/analytics/audios/${prefix}`, config);
      formatAudioAnalyticsData(data);
    } catch (error) {
      const status = error.response.status;
      if (status === 405) {
        setMessageText(
          'Not on your paid plan! If you want to upgrade please contact us.'
        );
        setMessageVariant('orange');
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchAudios();
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Message text={messageText} variant={messageVariant} />
          <div>
            <h2 className={classes.analyticsTitle}>Analytics for {prefix}</h2>
            <Grid item xs={12}>
              <MUIDataTable
                className='MUIDataTableTest'
                title='Top Audios'
                // DATA PROPS IS AN ARRAY OF ARRAYS
                data={audiosVisited}
                columns={keys}
                options={options}
              />
            </Grid>
          </div>
        </>
      )}
    </div>
  );
};

export default AudiosAnalyticsScreen;
