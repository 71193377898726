import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { Grid } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import Loader from '../../components/Loader'
import classes from './AnalyticsScreen.module.css'

const AffiliationsAnalyticsScreen = () => {
  const { prefix } = useParams()
  const [affiliationsVisited, setAffiliationsVisited] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const keys = [
    'Affiliation',
    //"Visits",
    'Number of Visits', //what we want
  ]

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`,
    },
  }

  const options = {
    filterType: 'checkbox',
    selectableRows: 'none', // <===== will turn off checkboxes in rows
    rowsPerPageOptions: [{ label: '10', value: 10 }],
  }
  const formatAnalyticsData = (analyticsData, conferenceTitle) => {
    // FILTER RETURNS ARRAY OF ONE OBJECT
    let categoryArray = analyticsData.filter((category) => {
      return category.label === 'Top Affiliations'
    })

    let affiliationsFinalArray

    if (categoryArray.length !== 0) {
      let formatedAffiliations = categoryArray[0].subtable.map((element) => {
        //Filter other prefixes
        if (element.label.trim().includes(conferenceTitle.trim())) {
          return {
            ...element,
            label: element.label,
          }
        } else {
          //return { ...element, label: element.label }
          return { ...element, label: '?' }
        }
      })

      let filteredAffiliationsArrayByTitle = formatedAffiliations.filter(
        (affiliation) => {
          return !affiliation.label?.includes('?')
        }
      )

      affiliationsFinalArray = filteredAffiliationsArrayByTitle.map((x) => {
        // CONSTRUCTING TABLE ROWS
        return [x.label, x.nb_events]
      })
    } else {
      affiliationsFinalArray = []
    }

    setAffiliationsVisited(affiliationsFinalArray)
    setIsLoading(false)
  }

  const getAffiliationAnalytics = async () => {
    try {
      const { data } = await axios.get(`/api/analytics/${prefix}`)
      const {
        data: { conferenceName },
      } = await axios.get(`/api/analytics/name/${prefix}`, config)
      const conferenceTitle = conferenceName[0].name
      formatAnalyticsData(data, conferenceTitle)
    } catch (error) {
      setIsLoading(false)
      console.log('Could not fetch conference name for this prefix')
    }
  }

  useEffect(() => {
    getAffiliationAnalytics()
  }, [])

  return (
    <div className='muiTableContainer'>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <h2 className={classes.analyticsTitle}>Analytics for {prefix}</h2>
          <Grid item xs={12}>
            <MUIDataTable
              className='MUIDataTableTest'
              title='Top Affiliations'
              // DATA PROPS IS AN ARRAY OF ARRAYS
              data={affiliationsVisited}
              columns={keys}
              options={options}
            />
          </Grid>
        </div>
      )}
    </div>
  )
}

export default AffiliationsAnalyticsScreen
