import React, { useState } from 'react'
import { Form, Button, Table } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import FormContainer from '../components/FormContainer'
import axios from 'axios'
import Message from '../components/Message'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import ScgCard from '../components/ScgCard'

const UserCreateScreen = () => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const navigate = useNavigate()

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [paidCategory, setPaidCategory] = useState('1')
  const [prefixes, setPrefixes] = useState([])
  const [addedConference, setAddedConference] = useState([])
  const [messageText, setMessageText] = useState('')
  const [messageVariant, setMessageVariant] = useState('')

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`,
    },
  }

  const createUser = async () => {
    try {
      const { data, status } = await axios.post(
        '/api/users/create',
        { name, email, password, isAdmin, paidCategory, prefixes },
        config
      )
      setMessageText('User Created!')
      setMessageVariant('green')
      setTimeout(() => {
        navigate(`/admin/userlist`)
      }, 1500)
    } catch (error) {
      const status = error.response.status
      if (status === 422) {
        setMessageText('User Already Exists!')
        setMessageVariant('orange')
      }
      if (status === 500) {
        setMessageText('Could not create user!')
        setMessageVariant('red')
      }
      if (status === 404) {
        setMessageText('Something went wrong!')
        setMessageVariant('red')
      }
    }
  }

  const submitHandler = (e) => {
    e.preventDefault()
    createUser(name, email, password, isAdmin, paidCategory, prefixes)
  }

  const addConferenceHandler = () => {
    setPrefixes([...prefixes, addedConference])
    setAddedConference('')
  }

  const deleteConferenceHandler = (prefix) => {
    setPrefixes(
      prefixes.filter((p) => {
        return p !== prefix
      })
    )
  }

  return (
    <ScgCard scrollable={true}>
      <Message text={messageText} variant={messageVariant}></Message>
      <FormContainer style={{ height: 'fit-content', overflow: 'hidden' }}>
        <h1>Create User</h1>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='name'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='name'
              placeholder='Enter name'
              value={name}
              required
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='email'>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter email'
              value={email}
              required
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='password'>
            <Form.Label>Password Address</Form.Label>
            <Form.Control
              type='password'
              placeholder='Enter password'
              value={password}
              required
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='isAdmin'>
            <Form.Label>Admin</Form.Label>
            <Form.Check
              type='switch'
              value={isAdmin}
              onChange={(e) => setIsAdmin(e.target.checked)}
            />
          </Form.Group>

          <Form.Group controlId='paidCategory'>
            <Form.Label>Paid Category</Form.Label>

            <Form.Select
              label='paidCategory'
              value={paidCategory}
              onChange={(e) => setPaidCategory(e.target.value)}
              style={
                paidCategory === '1'
                  ? { backgroundColor: '#CD7F32', color: 'white' }
                  : paidCategory === '2'
                  ? {
                      backgroundColor: 'rgb(189 187 187 / 86%)',
                      color: 'white',
                    }
                  : paidCategory === '3'
                  ? { backgroundColor: '#C29B0C', color: 'white' }
                  : { backgroundColor: 'grey' }
              }
            >
              <option value={1}>Bronze</option>
              <option value={2}>Silver</option>
              <option value={3}>Gold</option>
            </Form.Select>
          </Form.Group>

          <Form.Group controlId='addedConference'>
            <Form.Label>Add Conference</Form.Label>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <Form.Control
                type='text'
                label='addedConferece'
                value={addedConference}
                onChange={(e) => setAddedConference(e.target.value)}
              />

              <Button
                type='button'
                variant='success'
                disabled={addedConference.length === 0}
                style={{ marginLeft: '1rem' }}
                onClick={addConferenceHandler}
              >
                Add
              </Button>
            </div>
          </Form.Group>

          <div className='centralize'>
            <Table
              style={{ width: '50%' }}
              bordered
              hover
              responsive
              striped
              size='sm'
              variant='light'
              className='table-sm my-2'
            >
              <thead>
                <tr>
                  <th>Prefix</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                {prefixes.map((prefix) => (
                  <tr key={prefix}>
                    <td>{prefix}</td>
                    <td>
                      <Button
                        variant='danger'
                        className='btn-sm'
                        onClick={() => {
                          deleteConferenceHandler(prefix)
                        }}
                      >
                        <i className='fas fa-trash'></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <Link to='/admin/userlist' className='btn btn-light my-3'>
            Go Back
          </Link>
          <Button
            type='submit'
            className='buttonCta'
            disabled={prefixes.length === 0}
          >
            Create User
          </Button>
        </Form>
      </FormContainer>
    </ScgCard>
  )
}

export default UserCreateScreen
