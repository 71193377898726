import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../actions/userActions';

const LogoutScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(logout());
    setTimeout(() => {
      navigate('/login');
    }, 2000);
  }, []);

  return (
    <div
      style={{
        textAlign: 'center',
        background: 'rgba(255, 255, 255, 0.61)',
        borderRadius: '16px',
        boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
        backdropFilter: 'blur(6.9px)',
        border: '1px solid rgba(255, 255, 255, 0.4)',
        padding: '1rem',
        margin: '2rem 0',
      }}
    >
      {/* <h1>ePostersLive</h1> */}
      <p>We wish to see you soon!</p>
    </div>
  );
};

export default LogoutScreen;
