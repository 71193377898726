import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Grid } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import classes from './AnalyticsScreen.module.css';

const VideosAnalyticsScreen = () => {
  const { prefix } = useParams();

  const [videosVisited, setVideosVisited] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [messageText, setMessageText] = useState('');
  const [messageVariant, setMessageVariant] = useState('');

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  const keys = [
    'Video Title',
    'Number of Plays',
    'Number of Impressions',
    'Number of Full Views',
    'Average Time Watched in seconds',
  ];

  const options = {
    filterType: 'checkbox',
    selectableRows: 'none', // <===== will turn off checkboxes in rows
    rowsPerPageOptions: [{ label: '10', value: 10 }],
  };

  /* const isLabelClear = (videoView) => {
    if (
      videoView.label?.includes(`epostersonline.com/${prefix}/poster`) ||
      videoView.label?.includes(`epostersonline.com/${prefix}/video`) ||
      videoView.label?.includes(`epostersonline.com/${prefix}/node`) ||
      videoView.label?.includes(
        `scigen-archives-2.s3-eu-west-1.amazonaws.com`
      ) || !videoView.label?.includes(prefix)
    )
      return false
    else return true
  } */

  const isLabelClear = (videoView) => {
    if (
      videoView.label?.includes(
        `epostersonline-2.s3.amazonaws.com/${prefix}/${prefix}`
      )
    )
      return true;
    else return false;
  };

  const formatVideoAnalyticsData = async (videosArray) => {
    try {
      let { data } = await axios.get(
        `/api/analytics/codes/video/${prefix}`,
        config
      );
      let videosViewed = videosArray.filter(isLabelClear);
      let videosFinalArray = videosViewed.map((video) => {
        return [
          data[`${video.label.split('/').pop()}`],
          video.nb_plays,
          video.nb_impressions,
          video.nb_finishes,
          video.avg_time_watched,
        ];
      });
      setVideosVisited(videosFinalArray);
      setIsLoading(false);
    } catch (error) {
      const status = error.response.status;
      if (status === 400) {
        setMessageText('No data found');
        setMessageVariant('red');
        setIsLoading(false);
      }
    }
  };

  const fetchVideos = async () => {
    try {
      let { data } = await axios.get(`/api/analytics/videos/${prefix}`, config);
      formatVideoAnalyticsData(data);
    } catch (error) {
      const status = error.response.status;
      if (status === 405) {
        setMessageText(
          'Not on your paid plan! If you want to upgrade please contact us.'
        );
        setMessageVariant('orange');
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchVideos();
  }, []);

  return (
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Message text={messageText} variant={messageVariant} />
          <div>
            <h2 className={classes.analyticsTitle}>Analytics for {prefix}</h2>
            <Grid item xs={12}>
              <MUIDataTable
                className='MUIDataTableTest'
                title='Top Videos'
                // DATA PROPS IS AN ARRAY OF ARRAYS
                data={videosVisited}
                columns={keys}
                options={options}
              />
            </Grid>
          </div>
        </>
      )}
    </div>
  );
};

export default VideosAnalyticsScreen;
