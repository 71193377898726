import React from 'react'
import { useNavigate } from 'react-router-dom'

const SidebarItem = ({ title, link, icon }) => {
  const navigate = useNavigate()

  return (
    <li
      key={title}
      id={window.location.pathname.includes(link) ? 'active' : ''}
      className='row'
      onClick={() => {
        navigate(link)
      }}
    >
      <div id='icon'>{icon}</div>
      <div id='title'>{title}</div>
    </li>
  )
}

export default SidebarItem
