import React, { useEffect, useState } from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Table, Button, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import Searchbar from '../components/Searchbar'
import Message from '../components/Message'

const UserListScreen = () => {
  const [users, setUsers] = useState([])
  const [search, setSearch] = useState('')

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const [messageText, setMessageText] = useState('')
  const [messageVariant, setMessageVariant] = useState('')

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.token}`,
    },
  }

  useEffect(() => {
    listUsers()
  }, [])

  const listUsers = async () => {
    const { data } = await axios.get(`/api/users`, config)
    setUsers(data)
  }

  const deleteHandler = async (userId) => {
    if (
      window.confirm(`Are you sure you want to delete user with id ${userId}`)
    ) {
      try {
        const { data } = await axios.delete(
          `/api/users/delete/${userId}`,
          config
        )
        setUsers(
          users.filter((user) => {
            console.log(user._id)
            return user._id !== userId
          })
        )
        setMessageText('User Deleted!')
        setMessageVariant('green')
      } catch (error) {
        const status = error.response.status
        if (status === 202) {
          setMessageText('User Failed To Delete!')
          setMessageVariant('red')
        }
        if (status === 404) {
          setMessageText('User not found!')
          setMessageVariant('red')
        }
      }
    }
  }

  return (
    <div style={{ height: '100%', width: '100%', overflowY: 'scroll' }}>
      <Row>
        <h1>Users</h1>
        <Message text={messageText} variant={messageVariant}></Message>
        <div style={{ display: 'flex', marginBottom: '1rem' }}>
          <Searchbar
            value={search}
            placeholder='Search by email'
            onChange={(e) => setSearch(e.target.value)}
          />
          <LinkContainer to='/admin/create' style={{ marginLeft: 'auto' }}>
            <Button className='buttonCta'>
              <i className='fas fa-plus'></i> Create User
            </Button>
          </LinkContainer>
        </div>
      </Row>
      <Row>
        <Table striped bordered hover responsive className='table-sm'>
          <thead>
            <tr>
              <th>ID</th>
              <th>NAME</th>
              <th>EMAIL</th>
              <th>ADMIN</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {users.map(
              (user) =>
                user.name.includes(search.trim()) && (
                  <tr key={user._id}>
                    <td>{user._id}</td>
                    <td>{user.name}</td>
                    <td>
                      <a href={`mailto:${user.email}`}>{user.email}</a>
                    </td>
                    <td>
                      {user.isAdmin ? (
                        <i
                          className='fas fa-check'
                          style={{ color: 'green' }}
                        ></i>
                      ) : (
                        <i
                          className='fas fa-times'
                          style={{ color: 'red' }}
                        ></i>
                      )}
                    </td>
                    <td>
                      <LinkContainer to={`/admin/user/${user._id}/edit`}>
                        <Button variant='light' className='btn-sm'>
                          <i className='fas fa-edit'></i>
                        </Button>
                      </LinkContainer>
                      <Button
                        variant='danger'
                        className='btn-sm'
                        onClick={() => deleteHandler(user._id)}
                      >
                        <i className='fas fa-trash'></i>
                      </Button>
                    </td>
                  </tr>
                )
            )}
          </tbody>
        </Table>
      </Row>
    </div>
  )
}

export default UserListScreen
