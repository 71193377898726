import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Button, Form } from 'react-bootstrap';
import FormContainer from '../components/FormContainer';
import { useNavigate } from 'react-router-dom';
import ScgCard from '../components/ScgCard';
import axios from 'axios';
import { selectUserConfernce } from '../actions/userActions';

const HomeScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [conferenceList, setConferenceList] = useState([]);

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const userSelectedConfernce = useSelector(
    (state) => state.userSelectedConfernce
  );
  const { selectedConference } = userSelectedConfernce;

  const hasOneConference = (fetchedConferences) => {
    // IF USER HAS ONLY ONE CONF ASSIGNED SELECT THAT ONE
    if (fetchedConferences.length === 1) {
      dispatch(selectUserConfernce(fetchedConferences[0]));
    }
  };
  const getUserConferences = async () => {
    try {
      const {
        data: { userConferences },
      } = await axios.get(`/api/users/${userInfo._id}/conferences`);
      setConferenceList(userConferences);
      hasOneConference(userConferences);
    } catch (error) {
      console.log(error);
    }
  };

  const submitHandlerAdmin = (e) => {
    e.preventDefault();
    navigate(`/analytics/traffic/${selectedConference}`);
  };
  const submitHandlerUser = (e) => {
    e.preventDefault();
    navigate(`/analytics/traffic/${selectedConference}`);
  };

  useEffect(() => {
    getUserConferences();
  }, []);

  return (
    <>
      <ScgCard>
        {userInfo.isAdmin ? (
          <div className='HomeScreen'>
            <h1 className='my-3 py-3' style={{ color: '#166088' }}>
              Hello {userInfo.name} (admin),
              <br /> welcome to the Analytics Dashboard
            </h1>
            <FormContainer>
              <Form onSubmit={submitHandlerAdmin}>
                <Form.Group controlId='conf'>
                  <Form.Label>Type Conference Prefix</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='prefix2022'
                    value={selectedConference}
                    onChange={(e) =>
                      dispatch(selectUserConfernce(e.target.value))
                    }
                  ></Form.Control>
                </Form.Group>
                {selectedConference?.length !== 0 && (
                  <p>
                    You will be redirected to /analytics/traffic/
                    {selectedConference}
                  </p>
                )}
                <Button
                  type='submit'
                  variant='success'
                  disabled={selectedConference?.length === 0}
                  className='my-3'
                >
                  GO
                </Button>
              </Form>
            </FormContainer>
          </div>
        ) : (
          <div className='HomeScreen'>
            <h1 style={{ color: '#166088' }}>Welcome to ePostersLive</h1>
            <p style={{ color: 'black' }}></p>
            <FormContainer>
              <Form onSubmit={submitHandlerUser} style={{ minHeight: '50vh' }}>
                <Form.Group controlId='conference'>
                  <Form.Label className='my-3'>
                    Please choose a conference
                  </Form.Label>
                  <Form.Select
                    label='conference'
                    className='my-3'
                    value={selectedConference}
                    onChange={(e) => {
                      dispatch(selectUserConfernce(e.target.value));
                    }}
                  >
                    {conferenceList.map((conf) => (
                      <option key={conf} value={conf}>
                        {conf}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>

                <Button
                  type='submit'
                  variant='success'
                  className='my-3'
                  style={{ marginLeft: '1rem' }}
                  disabled={selectedConference?.length === 0}
                >
                  Go
                </Button>
              </Form>
            </FormContainer>
          </div>
        )}
      </ScgCard>
    </>
  );
};

export default HomeScreen;
