import React from 'react'

const Searchbar = (props) => {
  return (
    <div>
      <input
        style={{
          border: 'none',
          borderRadius: '1rem',
          padding: '0.5rem',
          backgroundColor: '#AEC3B0',
          color: 'black',
        }}
        type='search'
        placeholder={props.placeholder || 'Search'}
        value={props.value}
        onChange={props.onChange}
      />
      <i className='fas fa-search'></i>
    </div>
  )
}

export default Searchbar
