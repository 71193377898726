import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import { Table, Button, Row, Col } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

import axios from 'axios'
import Searchbar from '../components/Searchbar'
import { Link } from 'react-router-dom'

const ConferenceListScreen = () => {
  const [conferences, setConferences] = useState([])
  const [search, setSearch] = useState('')

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`,
    },
  }

  const deleteConferenceHandler = async (id, confPrefix) => {
    if (
      window.confirm(
        `Are you sure you want to delete Conference: ${confPrefix}?`
      )
    ) {
      const { data } = await axios.delete(`/api/conferences/${id}`, config)
      setConferences(
        conferences.filter((conf) => {
          return conf._id !== id
        })
      )
      console.log(data)
    }
  }

  const getConferences = async () => {
    try {
      const { data } = await axios.get('/api/conferences/', config)
      setConferences(data.conferences)
    } catch (error) {}
  }

  useEffect(() => {
    getConferences()
  }, [])

  return (
    <div
      style={{ backgroundColor: 'white', width: '100%', height: '100%' }}
      className='p-3 m-3'
    >
      <Row className='align-items-center'>
        <Col>
          <h1>Conferences</h1>
        </Col>
        <Col>
          <LinkContainer
            to={`/admin/conference/add`}
            style={{ float: 'right' }}
          >
            <Button className='buttonCta'>
              <i className='fas fa-plus'></i> Create Conference
            </Button>
          </LinkContainer>
        </Col>
      </Row>
      <Row>
        <Col>
          <Searchbar
            value={search}
            placeholder='Search by prefix'
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
      </Row>
      <Table bordered hover responsive className='table-sm scrollable'>
        <thead>
          <tr>
            <th>Name</th>
            <th>Prefix</th>
            <th>supportsPayments</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {conferences.map(
            (conference) =>
              conference.prefix.includes(search.trim().toLowerCase()) && (
                <tr key={conference._id}>
                  <td>{conference.name}</td>
                  <td>{conference.prefix}</td>
                  <td>
                    {conference.supportsPayments ? (
                      <LinkContainer
                        to={`/admin/conference/${conference.prefix}/paymentslist`}
                      >
                        <Button className='btn-sm buttonCta'>
                          View Payments
                        </Button>
                      </LinkContainer>
                    ) : (
                      <i className='fas fa-minus'></i>
                    )}
                  </td>
                  <td>
                    <LinkContainer
                      to={`/admin/conference/edit/${conference.prefix}`}
                    >
                      <Button variant='warning' className='btn-sm'>
                        <i className='fas fa-edit'></i>
                      </Button>
                    </LinkContainer>
                    <Button
                      variant='danger'
                      className='btn-sm'
                      onClick={() => {
                        deleteConferenceHandler(
                          conference._id,
                          conference.prefix
                        )
                      }}
                    >
                      <i className='fas fa-trash'></i>
                    </Button>
                  </td>
                  <td>
                    <LinkContainer to={`/submissioncodes/${conference.prefix}`}>
                      <Button className='btn-sm buttonSecondary'>Go to</Button>
                    </LinkContainer>
                  </td>
                </tr>
              )
          )}
        </tbody>
      </Table>
    </div>
  )
}

export default ConferenceListScreen
