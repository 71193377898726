import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import FormContainer from '../components/FormContainer';
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';
import Message from '../components/Message';
import classes from './SubmissionCodeRetrievalScreen.module.css';

const SubmissionCodeRetrievalScreen = () => {
  const navigate = useNavigate();
  let params = useParams();

  const primaryColor = 'green';
  const warningColor = 'orange';

  const [email, setEmail] = useState('');
  const [sentRequest, setSentRequest] = useState(false);
  const [confName, setConfName] = useState('');
  const [messageVariant, setMessageVariant] = useState(primaryColor);
  const [noDataMessage, setNoDataMessage] = useState('');

  const messageEmailNotFound = (
    <span>
      We couldn't find any data associated with this email address. Please double-check your entry. If the email is correct and you're still having
      issues, contact us at <a href='mailto:support@scigentech.com'>support@scigentech.com</a>.
    </span>
  );

  const messageEmailFound = 'Your submission code(s) have been sent to your email address. Please check your inbox and spam folder.';

  const sendEmail = async () => {
    // SEND EMAIL WITH CODES TO AUTHOR
    try {
      const { status } = await axios.get(`/api/submissioncodes/fetch/${email}/${params.prefix}`);
      setSentRequest(true);
      console.log(status);
      if (status === 204) {
        console.log('Did not find data for:', email);
        setNoDataMessage(messageEmailNotFound);
        setMessageVariant(warningColor);
      }
      if (status === 201) {
        console.log('Sending email to:', email);
        setNoDataMessage(messageEmailFound);
        setMessageVariant('green');
      }
    } catch (error) {
      // TODO: error handling
      const status = error.response.status;
      if (status === 404) {
        setNoDataMessage(messageEmailFound);
        setMessageVariant('red');
      }
    }
  };

  const getConferenceDetails = async () => {
    try {
      const { data: conference } = await axios.get(`/api/conferences/${params.prefix}`);
      setConfName(conference.name);
      if (conference.supportsPayments) {
        navigate(`/submissioncodes/payments/${conference.prefix}`);
      }
      console.log('this is a free conf');
    } catch (error) {
      const status = error.response.status;
      if (status === 404) {
        navigate('/404');
      }
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    sendEmail();
  };

  useEffect(() => {
    console.log('Rendered');
    getConferenceDetails();
  }, []);

  return (
    <div className={classes.cardContainer}>
      <div className={classes.flexItemLeft}>
        <p>Retrieve Your ePoster Submission Code(s)</p>
        <div>
          <p>Enter the email address used during abstract submission to receive your unique ePoster submission code(s) via email.</p>
          <p>If you have more than one accepted ePoster, we'll send all corresponding codes.</p>
        </div>
      </div>
      <div className={classes.flexItemRight}>
        <div className={classes.emailLabel}>
          Enter the email address used during your abstract submission to <strong>{confName}</strong>.
        </div>
        <FormContainer>
          <Form onSubmit={submitHandler}>
            <Form.Group controlId='email'>
              <Form.Label style={{ display: 'none' }}>
                Enter the email address used during your abstract submission to <strong>{confName}</strong>.
              </Form.Label>
              <Form.Control type='email' placeholder='johndoe@example.com' value={email} onChange={(e) => setEmail(e.target.value)}></Form.Control>
            </Form.Group>

            <div className={classes.buttonContainer}>
              <Button type='submit' className={classes.cta} style={{ textTransform: 'none' }} disabled={!email.length}>
                Send Submission Code(s)
              </Button>
            </div>
          </Form>
        </FormContainer>
        {sentRequest && <Message text={noDataMessage} variant={messageVariant} className='my-3' />}
      </div>
    </div>
  );
};

export default SubmissionCodeRetrievalScreen;
