import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Grid } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import Loader from '../../components/Loader'
import classes from './AnalyticsScreen.module.css'

const PostersAnalyticsScreen = () => {
  const { prefix } = useParams()

  const [postersVisited, setPostersVisited] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  // COLUMNS OF THE MUI TABLE
  const keys = [
    'Poster Code',
    //"Visits",
    'Number of Visits', //what we want
  ]

  const options = {
    filterType: 'checkbox',
    selectableRows: 'none', // <===== will turn off checkboxes in rows
    rowsPerPageOptions: [{ label: '10', value: 10 }],
  }

  const formatAnalyticsData = (analyticsData) => {
    // FILTER RETURNS ARRAY OF ONE OBJECT
    let categoryArray = analyticsData.filter((category) => {
      return category.label === 'Top Posters by Code'
    })

    let postersFinalArray

    if (categoryArray.length !== 0) {
      let formatedPosters = categoryArray[0].subtable.map((element) => {
        //Filter other prefixes
        if (element.label.includes(`https://epostersonline.com/${prefix}`)) {
          return {
            ...element,
            label: element.label
              // TODO: HANDLE DUBLICATES
              ?.split(`https://epostersonline.com/${prefix}/poster/`)[1]
              ?.toUpperCase(),
          }
        } else {
          //return { ...element, label: element.label }
          return { ...element, label: '?' }
        }
      })
      // console.log(formatedPosters)

      let filteredPostersArrayForParams = formatedPosters.filter((poster) => {
        return !poster.label?.includes('?')
      })

      let filterPostersArrayForEmptyPosterCodes =
        filteredPostersArrayForParams.filter((poster) => {
          return poster.label !== ''
        })

      let filterPostersArrayForSpaces =
        filterPostersArrayForEmptyPosterCodes.filter((poster) => {
          return !poster.label?.includes(' ')
        })

      postersFinalArray = filterPostersArrayForSpaces.map((x) => {
        // CONSTRUCTING TABLE ROWS
        return [x.label, x.nb_events]
      })
    } else {
      postersFinalArray = []
    }
    // console.log(postersFinalArray)
    setPostersVisited(postersFinalArray)
    setIsLoading(false)
  }

  const getPosterAnalytics = async () => {
    try {
      const { data } = await axios.get(`/api/analytics/${prefix}`)
      formatAnalyticsData(data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getPosterAnalytics()
  }, [])

  return (
    <div className='muiTableContainer'>
      {isLoading ? (
        <Loader />
      ) : (
        <div>
          <h2 className={classes.analyticsTitle}>Analytics for {prefix}</h2>
          <Grid item xs={12}>
            <MUIDataTable
              className='MUIDataTableTest'
              title='Top Posters'
              // DATA PROPS IS AN ARRAY OF ARRAYS
              data={postersVisited}
              columns={keys}
              options={options}
            />
          </Grid>
        </div>
      )}
    </div>
  )
}

export default PostersAnalyticsScreen
