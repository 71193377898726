import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { Button, Form } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import FormContainer from '../components/FormContainer'
import Message from '../components/Message'
import axios from 'axios'
import ScgCard from '../components/ScgCard'

const ConferenceEditScreen = () => {
  const warningColor = '#c15900'

  const navigate = useNavigate()
  let params = useParams()

  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`,
    },
  }

  const [confId, setConfId] = useState('')
  const [confName, setConfName] = useState('')
  const [confPrefix, setConfPrefix] = useState('')
  const [currency, setCurrency] = useState('usd')
  const [supportsPayments, setSupportsPayments] = useState(false)
  const [stripePriceId, setStripePriceId] = useState('')
  const [messageText, setMessageText] = useState('')
  const [messageVariant, setMessageVariant] = useState('')

  const updateConference = async () => {
    try {
      const { data: updatedData } = await axios.put(
        `/api/conferences/`,
        {
          _id: confId,
          name: confName,
          prefix: confPrefix,
          supportsPayments: supportsPayments,
          stripePriceId: stripePriceId,
          currency: currency,
        },
        config
      )

      setMessageText('Conference Updated')
      setMessageVariant('green')

      setConfId(updatedData._id)
      setConfName(updatedData.name)
      setConfPrefix(updatedData.prefix)
      setSupportsPayments(updatedData.supportsPayments)
      setStripePriceId(updatedData.stripePriceId)
      setCurrency(updatedData.currency)

      setTimeout(() => {
        navigate(`/admin/conferencelist`)
      }, 500)
    } catch (error) {
      const status = error.response.status
      if (status === 422) {
        setMessageText(`Conference with prefix: ${confPrefix} already exists`)
        setMessageVariant('orange')
      }

      if (status === 500) {
        setMessageText('Something went wrong')
        setMessageVariant('red')
      }
    }
  }

  const getConferenceDetails = async () => {
    try {
      const { data } = await axios.get(`/api/conferences/${params.prefix}`)
      setConfId(data._id)
      setConfName(data.name)
      setConfPrefix(data.prefix)
      setSupportsPayments(data.supportsPayments)
      setStripePriceId(data.stripePriceId)
      setCurrency(data.currency)
    } catch (error) {
      const status = error.response.status
      if (status === 404) {
        navigate('/404')
      }
    }
  }

  const submitHandler = async (e) => {
    e.preventDefault()
    console.log('Submitted')
    if (supportsPayments && stripePriceId.trim() === '') {
      setMessageText(
        'You have not set the price ID for the posters. A paid conference can not have 0 as price ID per poster.'
      )
      setMessageVariant(warningColor)
      return
    } else {
      updateConference()
    }
  }

  useEffect(() => {
    getConferenceDetails()
  }, [])

  return (
    <ScgCard>
      <h1 style={{ textAlign: 'center' }}>Edit Conference</h1>
      <Message text={messageText} variant={messageVariant} />
      <FormContainer>
        <Form onSubmit={submitHandler}>
          <Form.Group className='py-3' controlId='name'>
            <Form.Label>Conference Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter name'
              required
              value={confName}
              onChange={(e) => setConfName(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group className='py-3' controlId='prefix'>
            <Form.Label>Conference Prefix</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter prefix'
              required
              value={confPrefix}
              onChange={(e) => setConfPrefix(e.target.value.trim())}
            ></Form.Control>
          </Form.Group>
          <Form.Group className='py-3' controlId='payment'>
            <Form.Label>Conference Payments</Form.Label>
            <Form.Check
              type='switch'
              id='custom-switch'
              checked={supportsPayments}
              onChange={(e) => {
                console.log(e.target.checked)
                setSupportsPayments(e.target.checked)
              }}
            />
          </Form.Group>
          <Form.Group
            className='py-3'
            controlId='price'
            style={{ display: supportsPayments ? 'block' : 'none' }}
          >
            <Form.Label>Currency</Form.Label>
            <Form.Select
              label='currency'
              value={currency}
              onChange={(e) => setCurrency(e.target.value)}
            >
              <option value={'usd'}>Dollars - $</option>
              <option value={'eur'}>Euros - €</option>
            </Form.Select>
          </Form.Group>
          <Form.Group
            className='py-3'
            controlId='price'
            style={{ display: supportsPayments ? 'block' : 'none' }}
          >
            <Form.Label>Stripe Price ID</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter stripe price id'
              value={stripePriceId}
              onChange={(e) => setStripePriceId(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <div className='buttonContainer'>
            <LinkContainer to={`/admin/conferencelist/`}>
              <Button variant='secondary' className='buttonSecondary'>
                Back to Conference List
              </Button>
            </LinkContainer>
            <Button type='submit' className='buttonCta'>
              Update Conference
            </Button>
          </div>
        </Form>
      </FormContainer>
    </ScgCard>
  )
}

export default ConferenceEditScreen
