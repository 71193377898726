import React from 'react'
const Message = ({ text, variant }) => {
  return (
    <div
      style={{
        backgroundColor: variant,
        color: 'white',
        padding: '1rem',
        opacity: '80%',
      }}
    >
      {text}
    </div>
  )
}
export default Message
