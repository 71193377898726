import React from 'react'
import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ProtectedRoute = ({ children, redirectTo }) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  if (userInfo === null) return <Navigate to={redirectTo} />
  if (userInfo.token !== null) return children
}

const AdminProtectedRoute = ({ children, redirectTo }) => {
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  let isAdmin = userInfo?.isAdmin
  return isAdmin ? children : <Navigate to={redirectTo} />
}

export { ProtectedRoute, AdminProtectedRoute }
