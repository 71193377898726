import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { Grid } from '@material-ui/core'
import MUIDataTable from 'mui-datatables'
import Loader from '../../components/Loader'
import Message from '../../components/Message'
import ScgCard from '../../components/ScgCard'
import PeopleIcon from '@mui/icons-material/People'
import FacebookIcon from '@mui/icons-material/Facebook'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import classes from './TrafficAnalyticsScreen.module.css'
const TrafficAnalyticsScreen = () => {
  const { prefix } = useParams()
  const [directEntryVisitors, setDirectEntryVisitors] = useState(0)
  const [facebookVisitors, setFacebookVisitors] = useState(0)
  const [linkedInVisitors, setLinkedInVisitors] = useState(0)
  const [twitterVisitors, setTwitterVisitors] = useState(0)
  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin
  const [messageText, setMessageText] = useState('')
  const [messageVariant, setMessageVariant] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [websitesVisited, setWebsitesVisited] = useState([])
  const [width, setWidth] = useState(window.innerWidth)

  function handleWindowSizeChange() {
    setWidth(window.innerWidth)
  }
  const keys = [
    'SITE',
    //"Visits",
    'VISITS', //what we want
  ]
  const options = {
    filterType: 'checkbox',
    selectableRows: 'none', // <===== will turn off checkboxes in rows
    rowsPerPageOptions: [{ label: '10', value: 10 }],
  }
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${userInfo.token}`,
    },
  }
  const distinguishSocialNetworksData = (data) => {
    let facebookData = data?.subtable.find(
      (social) => social.label === 'Facebook'
    )
    setFacebookVisitors(facebookData?.nb_visits ? facebookData.nb_visits : 0)
    let linkedInData = data?.subtable.find(
      (social) => social.label === 'LinkedIn'
    )
    setLinkedInVisitors(linkedInData?.nb_visits ? linkedInData.nb_visits : 0)
    let twitterData = data?.subtable.find(
      (social) => social.label === 'Twitter'
    )
    setTwitterVisitors(twitterData?.nb_visits ? twitterData.nb_visits : 0)
    setIsLoading(false)
  }
  const distinguishAnalyticsData = (data) => {
    let { nb_visits: directEntryData } = data.find(
      (element) => element.label === 'Direct Entry'
    )
    setDirectEntryVisitors(directEntryData)
    // let websitesData = data.find((element) => element.label === 'Websites')
    let socialNetworksData = data.find(
      (element) => element.label === 'Social Networks'
    )
    // console.log(websitesData)
    distinguishSocialNetworksData(socialNetworksData)
    // TODO: SEARCH ENGINES
    let [{ subtable }] = data.filter((category) => {
      return category.label === 'Websites'
    })

    // hide stage.eposterslive.com from website visits
    let filteredWebsitesArray = subtable.filter((website) => {
      return !website.label?.includes('stage.eposterslive.com')
    })

    let websitesFinalArray = filteredWebsitesArray.map((x) => {
      return [x.label, x.nb_visits]
    })

    setWebsitesVisited(websitesFinalArray)
  }

  const getTrafficAnalytics = async () => {
    let analyticsData
    try {
      let { data } = await axios.get(`/api/analytics/traffic/${prefix}`, config)
      if (data.length !== 0) {
        analyticsData = data
        distinguishAnalyticsData(analyticsData)
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      console.log(error)
      const status = error.response.status
      if (status === 405) {
        setMessageText(
          'Not on your paid plan! If you want to upgrade please contact us.'
        )
        setMessageVariant('orange')
      }
      setIsLoading(false)
    }
  }
  useEffect(() => {
    getTrafficAnalytics()
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])
  return (
    // <Message text={messageText} variant={messageVariant} />
    <div>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Message text={messageText} variant={messageVariant} />
          <div className={classes.trafficContainer}>
            <div className={classes.flexItemLeft}>
              <ScgCard className={classes.flexItemLeft1}>
                <h2 style={{ textAlign: 'center' }}>Direct Visits</h2>
                <div
                  className={classes.analyticsSection}
                  style={{
                    backgroundColor: '#363E48',
                    color: 'white',
                  }}
                >
                  <PeopleIcon className={classes.trafficIcon} />
                  <div>{directEntryVisitors?.toLocaleString()}</div>
                </div>
              </ScgCard>
              <ScgCard className={classes.flexItemLeft2}>
                <h2 style={{ textAlign: 'center' }}>
                  Social Network Analytics
                </h2>
                <div
                  className={
                    classes.facebookSection + ' ' + classes.analyticsSection
                  }
                >
                  <FacebookIcon className={classes.trafficIcon} />{' '}
                  {facebookVisitors?.toLocaleString()}
                </div>
                <div
                  className={
                    classes.twitterSection + ' ' + classes.analyticsSection
                  }
                >
                  <TwitterIcon className={classes.trafficIcon} />{' '}
                  {twitterVisitors?.toLocaleString()}
                </div>
                <div
                  className={
                    classes.linkedInSection + ' ' + classes.analyticsSection
                  }
                >
                  <LinkedInIcon className={classes.trafficIcon} />{' '}
                  {linkedInVisitors?.toLocaleString()}
                </div>
              </ScgCard>
            </div>
            {width > 480 ? (
              <div className={classes.flexItemRight}>
                <Grid item xs={12}>
                  <div className='muiTableContainer'>
                    <MUIDataTable
                      className='MUIDataTableTest'
                      title='WEBSITE VISITS'
                      // DATA PROPS IS AN ARRAY OF ARRAYS
                      data={websitesVisited}
                      columns={keys}
                      options={options}
                    />
                  </div>
                </Grid>
              </div>
            ) : (
              <div className={classes.mobileMessage}>
                Website analytics are not available in mobile devices
              </div>
            )}
          </div>
        </>
      )}
    </div>
  )
}
export default TrafficAnalyticsScreen
