import React from 'react'

const SidebarSection = (props) => {
  return (
    <div>
      <div className='SidebarSection'>{props.title}</div>
      {props.children}
    </div>
  )
}

export default SidebarSection
